import { TokenData } from 'models';
import { Contract } from 'web3-eth-contract';

import { fromWei } from './contracts.helper';

export const getDepositAllowance = async (
  depositTokenData: TokenData,
  gameBridgeContract: Contract,
  connectedAddress: string
): Promise<string> => {
  const decimals = depositTokenData.decimals;
  const stakingAllowance = await depositTokenData.tokenContract.methods
    .allowance(connectedAddress, gameBridgeContract.options.address)
    .call();

  return fromWei(stakingAllowance, decimals);
};
