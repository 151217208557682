import { GameAccount, GameServer, GameSettingsErrors, Nft } from 'models';
import { State } from 'store';
import { GameSettingsState } from 'store/reducers';

import { createSelector } from '@reduxjs/toolkit';

const selectGameSettingsState = (state: State): GameSettingsState =>
  state.gameSettings;

export const selectAuthToken = createSelector(
  [selectGameSettingsState],
  (state: GameSettingsState): string => state.authToken
);

export const selectGameServers = createSelector(
  [selectGameSettingsState],
  (state: GameSettingsState): GameServer[] => state.gameServers
);

export const selectSelectedGameServer = createSelector(
  [selectGameSettingsState],
  (state: GameSettingsState): GameServer => state.selectedGameServer
);

export const selectSelectedGameServerApiUrl = createSelector(
  [selectSelectedGameServer],
  (gameServer: GameServer): string => gameServer.address
);

export const selectGameAccounts = createSelector(
  [selectGameSettingsState],
  (state: GameSettingsState): GameAccount[] => state.gameAccounts
);

export const selectNfts = createSelector(
  [selectGameSettingsState],
  (state: GameSettingsState): Nft[] => state.nfts
);

export const selectGameSettingsErrors = createSelector(
  [selectGameSettingsState],
  (state: GameSettingsState): GameSettingsErrors => state.errors
);
