import { fromWei, toWei } from 'helpers';
import {
  AllocationPoolItemAllocationData,
  AllocationPoolItemData,
  TokenData,
} from 'models';
import { Contract } from 'web3-eth-contract';

export const getAllocationPoolItemData = async (
  allocatePoolItemData: AllocationPoolItemData,
  allocateTokenData: TokenData,
  connectedAddress: string
): Promise<AllocationPoolItemAllocationData> => {
  const decimals = allocateTokenData.decimals;
  const allocationAllowance = await allocateTokenData.tokenContract.methods
    .allowance(
      connectedAddress,
      allocatePoolItemData.poolItemContract.options.address
    )
    .call();
  const maxTotalSupply = await allocatePoolItemData.poolItemContract.methods
    .vaultDepositsLimit()
    .call();

  // https://docs.google.com/document/d/19HkgYZoiS7h4S4-t-cMaJ-20rymyMv0dUsKDiGK0GM8/edit
  const currentStats: {
    totalSupply: string;
    allocationLimit: string;
    depositedAmount: string;
    allocationLeft: string;
    // isAllocated;
    // blockTimestamp;
  } = await allocatePoolItemData.poolItemContract.methods
    .getAddressStats(connectedAddress)
    .call();

  return {
    allocationAllowance: fromWei(allocationAllowance, decimals),
    allocatedTokens: fromWei(currentStats.depositedAmount, decimals),
    allocationLimit: fromWei(currentStats.allocationLimit, decimals),
    allocationLeft: fromWei(currentStats.allocationLeft, decimals),
    totalSupply: fromWei(currentStats.totalSupply, decimals),
    maxTotalSupply: fromWei(maxTotalSupply, decimals),
  };
};

export const canAllocate = async (
  contract: Contract,
  connectedAddress: string
): Promise<boolean> => {
  try {
    const canAllocate = await contract.methods
      .isAllocated(connectedAddress)
      .call();

    return canAllocate;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);

    return false;
  }
};

export const postAllocate = async (
  allocatePoolItemData: AllocationPoolItemData,
  allocateValue: string,
  decimals: number,
  connectedAddress: string
): Promise<unknown> => {
  const allocateValueWei = toWei(allocateValue, decimals);

  return await allocatePoolItemData.poolItemContract.methods
    .deposit(allocateValueWei)
    .send({
      from: connectedAddress,
      maxPriorityFeePerGas: null,
      maxFeePerGas: null,
    });
};
