// import binanceLogo from 'assets/svg/binance-logo-small.svg';

import WalletConnectProvider from '@walletconnect/web3-provider';

import { isProduction } from './environment.helper';

export const web3ModalAutoWalletConnect = true;

// TODO: Add other EVM chains if any - Polygon?
export const web3ModalProviderOptions = {
  // 'custom-binancechainwallet': {
  //   display: {
  //     logo: binanceLogo,
  //     name: 'Binance Wallet',
  //     description: 'Connect to your Binance Wallet',
  //   },
  //   package: true,
  //   connector: async (): Promise<unknown> => {
  //     let provider = null;
  //     if (window.BinanceChain === undefined) {
  //       // eslint-disable-next-line no-alert
  //       alert('No Binance Wallet found');
  //       throw new Error('No Binance Wallet found');
  //     } else {
  //       provider = window.BinanceChain;
  //       try {
  //         await provider.request({ method: 'eth_requestAccounts' });
  //       } catch {
  //         throw new Error('User Rejected');
  //       }
  //     }
  //     return provider;
  //   },
  // },
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      // infuraId: undefined, // required
      rpc: {
        56: 'https://bsc-dataseed.binance.org/',
        ...(!isProduction() && {
          97: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
        }),
      },
      network: 'binance',
      chainId: 97,
      // qrcodeModalOptions: {
      //   mobileLinks: [
      //     "rainbow",
      //     "metamask",
      //     "argent",
      //     "trust",
      //     "imtoken",
      //     "pillar",
      //   ],
      //   desktopLinks: [
      //     "encrypted ink",
      //   ]
      // }
    },
    // : {
    //     rpc: {
    //       1: 'https://api.mycryptoapi.com/eth',
    //       ...(!isProduction() && {
    //         97: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
    //       }),
    //     },
    //     network: 'ethereum',
    //     chainId: 97,
    //   },
  },
};

export const web3ModalOptions = {
  // network: "mainnet", // optional
  cacheProvider: true, // optional
  providerOptions: web3ModalProviderOptions, // required
  // theme: "dark",
  theme: {
    background: 'rgba(7, 24, 43, .7)',
    main: 'rgb(255, 255, 255)',
    secondary: 'rgb(136, 136, 136)',
    border: 'rgba(195, 195, 195, 0.14)',
    hover: '#010b1a',
  },
};
