import classNames from 'classnames';
import { Nft } from 'models';
import React from 'react';

export interface NftProps {
  nft: Nft;
  selected?: boolean;
  onClick?: () => void;
}

export const NftItem: React.FC<NftProps> = ({ nft, selected, onClick }) => (
  <div
    onClick={onClick}
    className={classNames({
      'nft-item': true,
      selected: selected,
    })}
  >
    <img src={nft.image} className="nft-item-image"></img>
    <div className="nft-item-edition">#{nft.edition}</div>
    <div className="nft-item-tier">{nft.attributes.landTier}</div>
  </div>
);
