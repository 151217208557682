import { ButtonWithSpinner, Message } from 'components';
import { MessageType } from 'helpers';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

export interface ChangeGameAccountPasswordModalProps {
  loading: boolean;
  errorMessage?: string;
  changeGameAccountPassword: (password: string) => void;
  hideModal: () => void;
}

export const ChangeGameAccountPasswordModal: React.FC<
  ChangeGameAccountPasswordModalProps
> = ({ errorMessage, loading, changeGameAccountPassword, hideModal }) => {
  const [password, setPassword] = useState<string>('');

  useEffect(() => {
    if (password && !loading && !errorMessage) {
      hideModal();
    }
  }, [loading]);

  const onHideModal = (): void => {
    hideModal();
  };

  const onChangeGameAccountPassword = (): void => {
    changeGameAccountPassword(password);
  };

  return (
    <Modal
      show={true}
      onHide={(): void => onHideModal()}
      backdrop="static"
      centered
      className="create-game-account-modal"
    >
      <Modal.Header className="justify-center">Change password</Modal.Header>
      <Modal.Body>
        <div>
          <label className="s-input-label mt-24">Password</label>
          <input
            className="s-input w-100 round"
            placeholder="Password"
            onChange={(event): void => setPassword(event.target.value)}
            value={password}
            disabled={loading}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <ButtonWithSpinner
          classes="btn btn-primary w-100"
          loading={loading}
          onClick={(): void => onChangeGameAccountPassword()}
          disabled={!password || loading}
        >
          Change password
        </ButtonWithSpinner>

        <button
          className="btn btn-secondary w-100 mt-16"
          onClick={(): void => onHideModal()}
          disabled={loading}
        >
          Close
        </button>

        {errorMessage ? (
          <div className="w-100">
            <Message
              messageType={MessageType.Error}
              descriptionText={errorMessage}
            ></Message>
          </div>
        ) : (
          ''
        )}
      </Modal.Footer>
    </Modal>
  );
};
