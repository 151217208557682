import { ChangeGameAccountPasswordModal, Message, NftItem } from 'components';
import { GameAccount, Nft } from 'models';
import React, { useState } from 'react';
import { Tab } from 'react-bootstrap';
import { MessageType } from 'helpers';
import { ButtonWithMessage } from 'components/ButtonWithMessage';

interface GameAccountItemSettingsTabContentProps {
  loading: boolean;
  errorMessage: string;
  account: GameAccount;
  assignedNft: Nft;
  onChangeGameAccountPassword: (password: string) => void;
  onUnassignNft: () => void;
  onHideModal: () => void;
}

export const GameAccountItemSettingsTabContent: React.FC<
  GameAccountItemSettingsTabContentProps
> = ({
  loading,
  errorMessage,
  account,
  assignedNft,
  onChangeGameAccountPassword,
  onUnassignNft,
  onHideModal,
}) => {
  const [showModal, setShowModal] = useState(null);

  const hideModal = (): void => {
    onHideModal();
    setShowModal(false);
  };

  return (
    <>
      <Tab.Pane eventKey="settings" unmountOnExit={true} mountOnEnter={true}>
        <div className="tab-content mb-16">
          In this section, you can view the detailed information about your
          account, such as assigned SkyLand NFT. You can also change your game
          account password.
        </div>

        <div className="text-background-gold  font-bold mb-16">
          Assigned SkyLand NFT
        </div>
        <div className="mb-16" style={{ margin: '0 auto 15px auto' }}>
          {assignedNft ? (
            <NftItem key={assignedNft.edition} nft={assignedNft}></NftItem>
          ) : (
            <Message
              messageType={MessageType.Primary}
              descriptionText="This account does not have any assigned SkyLand NFT because it was unassigned by you or or due to no longer being present on your wallet. This means that it cannot be used in the game. You can however still withdraw any SDT remaining in the account."
            />
          )}
        </div>

        <div className="text-background-gold font-bold mb-16">
          Account actions
        </div>

        <div className="d-flex flex-column gap-3">
          {!account?.nft_id || (
            <ButtonWithMessage
              buttonText="Unassign NFT"
              onClick={(): void => onUnassignNft()}
            >
              <div className="mb-2">
                After unassigning NFT from the account, the affected account
                will remain NFT-less and can only be used to withdraw any
                remaining SDT.
              </div>
              <div className="mb-2">
                The unassigned NFT can be used to create a new account on this
                or another game server.
              </div>
              <div>
                The progress of the &quot;orphaned&quot; motherland on this
                server will not be lost and it will remain permanently bound to
                the unassigned NFT id for the duration of the season. This means
                that if your wallet or some other wallet later on (during the
                duration of the season) creates a new game account on this
                server using the same NFT - it will be assigned the same
                motherland with its previous development state, units, conquered
                POIs, rSDT etc.
              </div>
            </ButtonWithMessage>
          )}
          <button
            className="btn btn-primary"
            onClick={(): void => setShowModal(true)}
            disabled={!account?.nft_id}
          >
            Change Password
          </button>
        </div>
      </Tab.Pane>

      {showModal ? (
        <ChangeGameAccountPasswordModal
          loading={loading}
          errorMessage={errorMessage}
          changeGameAccountPassword={(password): void =>
            onChangeGameAccountPassword(password)
          }
          hideModal={(): void => hideModal()}
        ></ChangeGameAccountPasswordModal>
      ) : (
        ''
      )}
    </>
  );
};
