import { GameServerCard, GameSettingsLogin, Nfts } from 'components';
import { contractsAvailable, SkynityCookie } from 'helpers';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import {
  GameAccount,
  GameServer,
  GameSettingsErrors,
  LoginResponseUser,
  TokenBalances,
} from 'models';
import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getGameAccounts,
  getGameServers,
  getNfts,
  login,
  loginFailure,
  loginSuccess,
  logout,
  resetGameSettingsErrors,
  selectAuthToken,
  selectBalances,
  selectConnectedAddress,
  selectGameAccounts,
  selectGameServer,
  selectGameServers,
  selectGameSettingsErrors,
  selectNftContract,
  selectNfts,
  selectSelectedGameServer,
  selectTokensData,
  selectWeb3,
} from 'store';
import { Contract } from 'web3-eth-contract';

import { GameAccounts } from './GameAccounts';

export const GameSettings = (): ReactElement => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const nfts = useSelector(selectNfts);
  const selectedGameServer = useSelector(selectSelectedGameServer);
  const gameAccounts: GameAccount[] = useSelector(selectGameAccounts);
  const gameServers: GameServer[] = useSelector(selectGameServers);
  const connectedAddress: string = useSelector(selectConnectedAddress);
  const tokensData = useSelector(selectTokensData);
  const nftContract: Contract = useSelector(selectNftContract);
  const authToken: string = useSelector(selectAuthToken);
  const errors: GameSettingsErrors = useSelector(selectGameSettingsErrors);
  const balances: TokenBalances = useSelector(selectBalances);
  const web3 = useSelector(selectWeb3);

  useEffect(() => {
    if (!authToken && connectedAddress && web3) {
      const authTokenFromCookie = Cookies.get(SkynityCookie.AuthToken);

      if (authTokenFromCookie) {
        try {
          const decodedToken = jwtDecode(authTokenFromCookie);
          const isTokenForAddress = decodedToken.sub === connectedAddress;
          const notExpired = Date.now() / 1000 < decodedToken.exp;
          const selectedGameServerFromCookie = JSON.parse(
            Cookies.get(SkynityCookie.SelectedGameServer)
          );

          if (isTokenForAddress && notExpired) {
            dispatch(
              loginSuccess({
                auth_token: authTokenFromCookie,
                user: {
                  gameAccounts: [],
                } as LoginResponseUser,
                isNewUser: false,
              })
            );
            dispatch(selectGameServer(selectedGameServerFromCookie));
          }
        } catch {
          dispatch(loginFailure());
        }
      }
    }
  }, [authToken, connectedAddress, web3]);

  useEffect(() => {
    if (connectedAddress && authToken && selectedGameServer && !gameServers) {
      dispatch(getGameServers);
    }
  }, [connectedAddress, authToken, selectedGameServer, gameServers]);

  useEffect(() => {
    if (connectedAddress && authToken && selectedGameServer) {
      dispatch(getGameAccounts());
    }
  }, [connectedAddress, selectedGameServer]);

  useEffect(() => {
    if (connectedAddress && nftContract && authToken) {
      dispatch(getNfts());
    }
  }, [connectedAddress, nftContract, authToken]);

  useEffect(() => {
    if (authToken || errors) {
      setLoading(false);
    }
  }, [authToken, errors]);

  useEffect(
    () =>
      // Return a cleanup function
      () => {
        // This code will run when the component is unmounted
        dispatch(resetGameSettingsErrors(null));
      },
    []
  );

  const onSelectGameServer = (gameServer: GameServer): void => {
    dispatch(resetGameSettingsErrors());
    dispatch(selectGameServer(gameServer));
  };

  const onLogin = (): void => {
    setLoading(true);
    dispatch(resetGameSettingsErrors());
    dispatch(login());
  };

  const onLogout = (): void => {
    dispatch(logout());
  };

  return (
    <>
      {connectedAddress && contractsAvailable(tokensData) ? (
        <div className="container game-settings">
          <div className="container-inner">
            {authToken && selectedGameServer && gameAccounts && balances ? (
              <>
                <GameServerCard
                  selectedGameServer={selectedGameServer}
                  onLogout={(): void => onLogout()}
                />
                <GameAccounts
                  nfts={nfts}
                  gameServers={gameServers}
                  gameAccounts={gameAccounts}
                  error={errors?.gameAccount}
                />
                <Nfts
                  nfts={nfts}
                  gameServers={gameServers}
                  error={errors?.nft}
                />
              </>
            ) : (
              <GameSettingsLogin
                loading={loading}
                error={errors?.login || errors?.servers}
                onLogin={(): void => onLogin()}
                onSelectGameServer={(gameServer: GameServer): void =>
                  onSelectGameServer(gameServer)
                }
              />
            )}
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};
