import {
  GameAccount,
  GameServer,
  GameSettingsErrors,
  LoginResponse,
  Nft,
} from 'models';

import { createAction } from '@reduxjs/toolkit';

// LOGIN
export const login = createAction('LOGIN');

export const loginSuccess = createAction<LoginResponse>('LOGIN_SUCCESS');

export const loginFailure = createAction('LOGIN_FAILURE');

// LOGOUT
export const logout = createAction('LOGOUT');

// SERVERS
export const getGameServers = createAction('GET_GAME_SERVERS');

export const getGameServersSuccess = createAction<GameServer[]>(
  'GET_GAME_SERVERS_SUCCESS'
);
export const getGameServersFailure = createAction('GET_GAME_SERVERS_FAILURE');

export const selectGameServer = createAction<GameServer>('SELECT_GAME_SERVER');

// GAME ACCOUNTS
export const createGameAccount = createAction<{
  accountName: string;
  accountPassword: string;
  nftId: number;
}>('CREATE_GAME_ACCOUNT');

export const createGameAccountSuccess = createAction<GameAccount>(
  'CREATE_GAME_ACCOUNT_SUCCESS'
);

export const createGameAccountFailure = createAction(
  'CREATE_GAME_ACCOUNT_FAILURE'
);

export const getGameAccounts = createAction('GET_GAME_ACCOUNTS');

export const getGameAccountsSuccess = createAction<GameAccount[]>(
  'GET_GAME_ACCOUNTS_SUCCESS'
);

export const getGameAccountsFailure = createAction('GET_GAME_ACCOUNTS_FAILURE');

// TODO: Move to nft store
export const getNfts = createAction('GET_NFTS');

export const getNftsSuccess = createAction<Nft[]>('GET_NFTS_SUCCESS');

export const getNftsFailure = createAction('GET_NFTS_FAILURE');

export const setGameSettingsErrors = createAction<GameSettingsErrors>(
  'SET_GAME_SETTINGS_ERRORS'
);

export const resetGameSettingsErrors = createAction(
  'RESET_GAME_SETTINGS_ERROR'
);

export const resetGameSettingsState = createAction('RESET_GAME_SETTINGS_STATE');
