import { DataItem } from 'components';
import { roundNumber } from 'helpers';
import { MainTokenSymbol, rSDTTokenSymbol } from 'models';
import React from 'react';

interface GameAccountItemDetailsProps {
  inAccountSdt: string;
  inVesting: string;
  inWallet: string;
  inAccountRsdt: string;
  pendingDeposit: string;
  pendingWithdrawal: string;
}

export const GameAccountItemDetails: React.FC<GameAccountItemDetailsProps> = ({
  inAccountSdt,
  inVesting,
  inWallet,
  inAccountRsdt,
  pendingDeposit,
  pendingWithdrawal,
}) => (
  <div className="staking-pool-item-details">
    {/* <div> */}
    <DataItem
      label={inVesting === undefined ? 'In wallet' : 'In vesting'}
      data={inVesting === undefined ? inWallet : inVesting}
      symbol={MainTokenSymbol.SDT}
      iconClasses={'fa fa-cubes-stacked'}
    />

    <DataItem label="In game account" iconClasses={'fa fa-wallet'}>
      {roundNumber(inAccountSdt)} {MainTokenSymbol.SDT} /{' '}
      {roundNumber(inAccountRsdt)} {rSDTTokenSymbol}
    </DataItem>
    {/* </div> */}

    {/* <div>
      <DataItem
        label="Pending deposit"
        data={pendingDeposit}
        symbol={MainTokenSymbol.SDT}
        iconClasses={'fa fa-hourglass'}
      /> */}

    <DataItem
      label="Pending withdrawal"
      data={pendingWithdrawal}
      symbol={MainTokenSymbol.SDT}
      iconClasses={'fa fa-hourglass'}
    />
    {/* </div> */}
  </div>
);
