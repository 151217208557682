import { CGStableCoins } from 'helpers';
import { StableTokenPrices, StableTokenPricesResponse } from 'models';
import { from, Observable } from 'rxjs';

export class StablecoinService {
  // TODO: Fix Promise / Observable
  public static getStablecoinPrices(): Observable<StableTokenPrices> {
    const getPrices = async (): Promise<StableTokenPricesResponse> => {
      let prices: StableTokenPricesResponse;

      try {
        const response: Response = await fetch(
          `${process.env.REACT_APP_CG_API_URL}?ids=${Object.values(
            CGStableCoins
          ).join(',')}&vs_currencies=usd`
        );
        prices = await response.json();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);

        //* Fallback for stablecoin prices
        prices = {
          [CGStableCoins.BUSD]: {
            usd: 1,
          },
          [CGStableCoins.USDT]: {
            usd: 1,
          },
          [CGStableCoins.USDC]: {
            usd: 1,
          },
          [CGStableCoins.DAI]: {
            usd: 1,
          },
        };
      }

      return prices;
    };

    const asyncFunction = async (): Promise<StableTokenPrices> => {
      const prices = await getPrices();
      let processedPrices = {} as StableTokenPrices;
      Object.entries(prices).forEach(
        ([key, value]: [string, { usd: number }]) => {
          processedPrices = { ...processedPrices, [key]: value.usd.toString() };
        }
      );

      return { ...processedPrices };
    };

    return from(asyncFunction());
  }
}
