import BigNumber from 'bignumber.js';
import { ButtonWithSpinner } from 'components/ButtonWithSpinner';
import { PercentageButtons } from 'components/PercentageButtons';
import { isCorrectValue, MessageText, MessageType, numbersOnly } from 'helpers';
import {
  DepositSource,
  TokenData,
  VestingPoolData,
  VestingPoolVestingData,
} from 'models';
import React, { useEffect, useState } from 'react';
import { Tab } from 'react-bootstrap';

import { GameAccountItemDepositTabContentSourceSelector } from './GameAccountItemDepositTabContentSourceSelector';
import { Message } from 'components/Message';

interface GameAccountItemDepositTabContentProps {
  connectedAddress: string;
  loading: boolean;
  depositAllowance: string;
  inWallet: string;
  tokenData: TokenData;
  vestingPoolsData: VestingPoolData[];
  selectedVestingPoolData: VestingPoolData;
  selectedVestingPoolVestingData: VestingPoolVestingData;
  pendingDeposit: string;
  onApprove: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onSelectVestingPool: (vestingPoolData: VestingPoolData) => void;
  onDepositFromWallet: (depositValue: string) => void;
  onDepositFromVesting: (depositValue: string) => void;
}

export const GameAccountItemDepositTabContent: React.FC<
  GameAccountItemDepositTabContentProps
> = ({
  connectedAddress,
  loading,
  depositAllowance,
  inWallet,
  tokenData,
  vestingPoolsData,
  selectedVestingPoolData,
  selectedVestingPoolVestingData,
  pendingDeposit,
  onApprove,
  onSelectVestingPool,
  onDepositFromWallet,
  onDepositFromVesting,
}) => {
  const [depositSource, setDepositSource] = useState<DepositSource>('wallet');
  const [depositValue, setDepositValue] = useState<string>('');
  const depositValueBN = new BigNumber(depositValue);
  const depositAllowanceBN = new BigNumber(depositAllowance);
  const availableSdtInWalletBN = new BigNumber(inWallet);

  useEffect(() => {
    if (!loading) {
      setDepositValue('0');
    }
  }, [loading]);

  const inputDisabled = (): boolean => {
    if (loading) {
      return true;
    }

    if (depositSource === 'wallet') {
      return availableSdtInWalletBN.isEqualTo(0);
    } else if (depositSource === 'vesting') {
      return (
        !selectedVestingPoolVestingData ||
        +selectedVestingPoolVestingData?.amountLeft === 0
      );
    }

    return true;
  };

  const depositButtonDisabled = (): boolean => {
    let isGreaterThanAvailable;

    if (depositSource === 'wallet') {
      isGreaterThanAvailable = depositValueBN.isGreaterThan(
        availableSdtInWalletBN
      );
    } else if (depositSource === 'vesting') {
      isGreaterThanAvailable = depositValueBN.isGreaterThan(
        new BigNumber(+selectedVestingPoolVestingData?.amountLeft)
      );
    }

    return inputDisabled() || +depositValue == 0 || isGreaterThanAvailable;
  };

  const onApplyPercentage = (percentage: number): void => {
    const value =
      depositSource === 'wallet'
        ? availableSdtInWalletBN
        : new BigNumber(selectedVestingPoolVestingData.amountLeft);

    const roundedNumber = value
      .times(percentage)
      .div(100)
      .toFixed(tokenData.decimals);

    setDepositValue(roundedNumber);
  };

  const onSetDepositSource = (depositSource: DepositSource): void => {
    setDepositValue('0');
    setDepositSource(depositSource);
    onSelectVestingPool(undefined);
  };

  const onDepositValueChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const value = event.target.value;

    if (isCorrectValue(value, tokenData.decimals)) {
      setDepositValue(value);
    }
  };

  const onDepositButtonClick = (): void => {
    if (depositSource === 'wallet') {
      onDepositFromWallet(depositValue);
    } else if (depositSource === 'vesting') {
      onDepositFromVesting(depositValue);
    }
  };

  const canDepositFromWallet =
    depositSource === 'wallet' &&
    depositAllowanceBN.isGreaterThan(0) &&
    depositAllowanceBN.isGreaterThan(+depositValue);
  const canDeposit = depositSource === 'vesting' || canDepositFromWallet;
  const button = canDeposit ? (
    <ButtonWithSpinner
      text="Deposit"
      classes="btn btn-primary section-action-button"
      disabled={depositButtonDisabled()}
      onClick={(): void => onDepositButtonClick()}
      loading={loading}
    />
  ) : (
    <ButtonWithSpinner
      text="Approve"
      classes="btn btn-primary section-action-button"
      onClick={onApprove}
      loading={loading}
    />
  );

  return (
    <Tab.Pane eventKey="deposit" unmountOnExit={true} mountOnEnter={true}>
      {+pendingDeposit ? (
        <Message
          messageType={MessageType.Error}
          descriptionText="You have another ongoing deposit from your wallet/vesting on this
          server - please refresh the page after a couple of minutes."
        />
      ) : (
        <>
          <div className="tab-content">
            <div className="unstake-section">
              <div className="unstake-section-description">
                Deposit your SkyDust (SDT) tokens into the game. You can use
                tokens available in your wallet or you can transfer tokens from
                your vesting pool (if available).
              </div>
              <div className="unstake-section-description">
                The deposited tokens will be converted into the rSDT (raw
                SkyDust) in-game currency at a 6:1 ratio (you will get 6 rSDT
                for every 1 deposited SDT).
              </div>
              <div className="unstake-section-actions">
                <GameAccountItemDepositTabContentSourceSelector
                  connectedAddress={connectedAddress}
                  tokenData={tokenData}
                  depositSource={depositSource}
                  loading={loading}
                  vestingPoolsData={vestingPoolsData}
                  selectedVestingPoolData={selectedVestingPoolData}
                  onSelectVestingPool={(
                    vestingPoolData: VestingPoolData
                  ): void => onSelectVestingPool(vestingPoolData)}
                  onSetDepositSource={(depositSource: DepositSource): void =>
                    onSetDepositSource(depositSource)
                  }
                />

                {canDepositFromWallet || selectedVestingPoolData ? (
                  <>
                    <input
                      className="s-input"
                      placeholder="0.00"
                      onChange={onDepositValueChange}
                      value={depositValue}
                      onKeyPress={numbersOnly}
                      disabled={inputDisabled()}
                    />

                    <PercentageButtons
                      disabled={inputDisabled()}
                      applyPercentage={(percentage: number): void =>
                        onApplyPercentage(percentage)
                      }
                    />
                  </>
                ) : (
                  ''
                )}

                {depositSource === 'wallet' && !canDepositFromWallet ? (
                  <div className="mb-16">{MessageText.Approve}</div>
                ) : (
                  ''
                )}

                {button}
              </div>

              {/* TODO */}
              {/* {withdrawValueBN.isGreaterThan(totalTokensInGameBn) && (
            <Message
              messageType={MessageType.Error}
              descriptionText={MessageText.WithdrawValueGreaterThanAvailable}
            />
          )} */}
            </div>
          </div>
        </>
      )}
    </Tab.Pane>
  );
};
