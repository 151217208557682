export enum MessageType {
  Error = 'error',
  Primary = 'primary',
  Secondary = 'secondary',
}

export enum MessageText {
  ValueGreaterThanApproved = 'Entered value is greater than approved value. Please approve first',
  UnstakingValueNotGreaterThanStaked = 'Value should not be greater than staked tokens',
  NoTokens = "You don't have any tokens.",
  NothingToClaim = "You don't have anything to claim yet. Please come back later",
  EverythingClaimed = 'You have claimed everything from this pool',
  Approve = 'You need to approve your tokens first',
  Fees = 'Make sure to have enough founds available for network fees ',
  WithdrawValueGreaterThanAvailable = 'You cannot withdraw more tokens than you have',
  ServerNotResponding = 'Server not responding.',
}
