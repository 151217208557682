import { InfoCard } from 'components';
import { AppChain, getChainName, switchToPrimaryChain } from 'helpers';
import React from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { selectConnectedAddress, selectWeb3 } from 'store';
import { usePermittedNetworkStatus } from 'helpers/connection.hooks';

export const Placeholder: React.FC = () => {
  const web3 = useSelector(selectWeb3);
  const connectedPermittedNetwork = usePermittedNetworkStatus();

  const connectedAddress = useSelector(selectConnectedAddress);
  // const tokensData = useSelector(selectTokensData);

  if (connectedAddress) {
    if (!connectedPermittedNetwork) {
      // if (!contractsAvailable(tokensData)) {
      const wrongNetworkText = `Please switch your network to ${getChainName(
        process.env.REACT_APP_CHAIN as AppChain
      )} Mainnet to continue your journey`;

      return (
        <InfoCard
          headerText="Wrong network selected"
          description={wrongNetworkText}
        >
          <Button
            className="btn btn-secondary"
            onClick={(): void => {
              switchToPrimaryChain(web3);
            }}
          >
            Switch network
          </Button>
        </InfoCard>
      );
    }
  } else {
    return (
      <InfoCard
        headerText="Connect Wallet"
        description="Get access to all functionalities of our dApp."
      ></InfoCard>
    );
  }
};
