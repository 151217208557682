import { Footer, Header, Preloader, Skynity } from 'components';
import {
  isPermittedNetwork,
  web3ModalAutoWalletConnect,
  web3ModalOptions,
} from 'helpers';
import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllocationPools,
  getGameBridgeContract,
  getLPPairsContracts,
  getNftTokenContract,
  getStablecoinPrices,
  getStakingPools,
  getTokensData,
  getVestingPools,
  logout,
  selectInitializingFinished,
  selectShowLoader,
  selectWeb3,
  selectWeb3Modal,
  selectWeb3Provider,
  setConnectedAddress,
  setInitialData,
  setInitializingFinished,
  setShowLoader,
  setWeb3Modal,
  setWeb3Provider,
} from 'store';
import Web3 from 'web3';
import Web3Modal from 'web3modal';

import WalletConnectProvider from '@walletconnect/web3-provider';
import { NetworksParams } from 'config';

const App: React.FC = () => {
  const dispatch = useDispatch();
  const showLoader = useSelector(selectShowLoader);
  const web3: Web3 = useSelector(selectWeb3);
  const web3Modal: Web3Modal = useSelector(selectWeb3Modal);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const web3Provider: any = useSelector(selectWeb3Provider);
  const initializingFinished = useSelector(selectInitializingFinished);

  useEffect(() => {
    const web3Modal: Web3Modal = new Web3Modal(web3ModalOptions);
    dispatch(setWeb3Modal(web3Modal));
  }, []);

  // Show web3modal on initial page load (or autoreconnenct if cached provider)
  // TODO: set conditionally by autoWalletConnect
  useEffect(() => {
    if (web3ModalAutoWalletConnect && web3Modal?.cachedProvider) {
      connectWallet();
    } else if (web3Modal && !web3Modal.cachedProvider) {
      dispatch(setShowLoader(false));
    }
  }, [web3Modal]);

  useEffect(() => {
    if (web3Provider) {
      initEvmChainData();
    }
  }, [web3Provider]);

  const initEvmChainData = async (): Promise<void> => {
    try {
      const web3 = new Web3(web3Provider);
      const gasPrice = await web3.eth.getGasPrice();
      const networkId: number = await web3.eth.net.getId();
      const networkIdString: string = networkId.toString();
      const accounts = await web3.eth.getAccounts();

      web3Provider.on('accountsChanged', () => {
        dispatch(logout());
      });
      web3Provider.on('chainChanged', () => {
        dispatch(logout());
      });
      web3Provider.on(
        'disconnect',
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {
          dispatch(logout());
        }
      );

      dispatch(
        setInitialData({
          web3,
          gasPrice,
          connectedNetwork: Object.values(NetworksParams).find(
            (network) => network.chainId == networkId
          ),
          connectedAddress: accounts[0],
        })
      );

      if (isPermittedNetwork(networkId)) {
        dispatch(getStablecoinPrices());
        dispatch(getTokensData({ networkId: networkIdString, web3 }));
        dispatch(getLPPairsContracts({ networkId: networkIdString, web3 }));
        dispatch(getNftTokenContract({ networkId: networkIdString, web3 }));
        dispatch(getGameBridgeContract({ networkId: networkIdString, web3 }));
        dispatch(
          getStakingPools({
            networkId: networkIdString,
            connectedAddress: accounts[0],
            web3,
          })
        );
        dispatch(
          getVestingPools({
            networkId: networkIdString,
            connectedAddress: accounts[0],
            web3,
          })
        );
        dispatch(
          getAllocationPools({
            networkId: networkIdString,
            web3,
            connectedAddress: accounts[0],
          })
        );
      }

      dispatch(setInitializingFinished(true));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }

    dispatch(setShowLoader(false));
  };

  const connectWallet = async (): Promise<void> => {
    try {
      const provider = await web3Modal.connect();
      dispatch(setWeb3Provider(provider));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('ERROR@connectWallet:', error);
      dispatch(setShowLoader(false));
    }
  };

  const disconnectWallet = async (): Promise<void> => {
    dispatch(setWeb3Provider(null));
    dispatch(setConnectedAddress(null));

    if (web3.currentProvider instanceof WalletConnectProvider) {
      await (web3.currentProvider as WalletConnectProvider).disconnect();
    }

    web3Modal.clearCachedProvider();
    window.location.reload();
  };

  return (
    <Fragment>
      {showLoader && <Preloader transparent={initializingFinished} />}
      <div className="app-container">
        <Header
          connectWallet={connectWallet}
          disconnectWallet={disconnectWallet}
        />
        <Skynity />
      </div>
      <Footer />
    </Fragment>
  );
};

export default App;
