/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-unused-vars */
import classNames from 'classnames';
import {
  GameAccountItemDepositTabContent,
  GameAccountItemDetails,
  GameAccountItemSettingsTabContent,
  GameAccountItemWithdrawTabContent,
} from 'components';
import { Message } from 'components/Message';
import {
  fromWei,
  getDepositAllowance,
  getVestingPoolData,
  MAX_UINT256,
  MessageText,
  MessageType,
  postDepositFromVestingToGameBridge,
} from 'helpers';
import {
  getGameBridgeData,
  postDepositFromWallet,
  postWithdrawFromGameAccount,
} from 'helpers/game-bridge';
import {
  GameAccount,
  GameAccountTab,
  GameBridgeAccountData,
  Nft,
  TokensData,
  VestingPoolData,
  VestingPoolVestingData,
} from 'models';
import React, { useEffect, useRef, useState } from 'react';
import { Accordion, Nav, Tab } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { GameSettingsService } from 'services';
import {
  getBalances,
  getGameAccounts,
  getGameServers,
  logout,
  selectAuthToken,
  selectBalances,
  selectConnectedAddress,
  selectGameBridgeContract,
  selectGameServers,
  selectSelectedGameServer,
  selectTokensData,
  selectVestingPools,
  selectWeb3,
  setShowLoader,
} from 'store';
import { Contract } from 'web3-eth-contract';

import { GameAccountItemHeader } from './GameAccountItemHeader';

interface GameAccountItemProps {
  index: number;
  assignedNft: Nft;
  account: GameAccount;
}

export const GameAccountItem: React.FC<GameAccountItemProps> = ({
  index,
  assignedNft,
  account,
}) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [depositAllowance, setDepositAllowance] = useState(null);
  const [selectedVestingPoolData, setSelectedVestingPoolData] =
    useState<VestingPoolData>(null);
  const [selectedVestingPoolVestingData, setSelectedVestingPoolVestingData] =
    useState<VestingPoolVestingData>(null);
  const [activeTab, setActiveTab] = useState<GameAccountTab>('deposit');
  const [gameBridgeAccountData, setGameBridgeAccountData] =
    useState<GameBridgeAccountData>(null);
  const dispatchApp = useDispatch();
  const gameServers = useSelector(selectGameServers);
  const selectedGameServer = useSelector(selectSelectedGameServer);
  const vestingPoolsData = useSelector(selectVestingPools);
  const connectedAddress: string = useSelector(selectConnectedAddress);
  const tokensData: TokensData = useSelector(selectTokensData);
  const gameBridgeContract: Contract = useSelector(selectGameBridgeContract);
  const balances = useSelector(selectBalances);
  const web3 = useSelector(selectWeb3);
  const authToken = useSelector(selectAuthToken);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-boolean-literal-compare
    if (loading === false) {
      getData();
    }
  }, [loading]);

  useEffect(() => {
    getData();
  }, [activeTab]);

  useEffect(() => {
    if (gameServers && selectedGameServer) {
      const hasSelectedGameServer = gameServers?.some(
        (gameServer) => gameServer.id === selectedGameServer?.id
      );

      if (!hasSelectedGameServer) {
        dispatchApp(logout());
      }
    }
  }, [gameServers, selectedGameServer]);

  const accordionHeader = useRef(null);
  useEffect(() => {
    const accordionButton =
      accordionHeader.current.querySelector('.accordion-button');
    accordionButton.style.backgroundImage = assignedNft
      ? `url(${assignedNft.image})`
      : 'none';
  }, [assignedNft]);

  const getData = (): void => {
    getDepositAllowanceAmount();
    dispatchApp(getGameAccounts());
    dispatchApp(getGameServers());
    dispatchApp(
      getBalances({
        tokensData: Object.values(tokensData),
        connectedAddress,
      })
    );
    getGameBridgeAccountData();

    if (selectedVestingPoolData) {
      getSelectedVestingPoolData(selectedVestingPoolData.vestingPoolContract);
    }
  };

  const getDepositAllowanceAmount = async (): Promise<void> => {
    try {
      const depositAllowance: string = await getDepositAllowance(
        tokensData.SDT,
        gameBridgeContract,
        connectedAddress
      );

      setDepositAllowance(depositAllowance);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const getGameBridgeAccountData = async (): Promise<void> => {
    try {
      const payload = await getGameBridgeData(
        connectedAddress,
        tokensData.SDT,
        gameBridgeContract,
        selectedGameServer.id
      );

      setGameBridgeAccountData(payload);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const getSelectedVestingPoolData = async (
    vestingPoolContract: Contract
  ): Promise<void> => {
    try {
      const payload = await getVestingPoolData(
        connectedAddress,
        tokensData.SDT,
        vestingPoolContract
      );

      setSelectedVestingPoolVestingData(payload);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const onApprove = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): Promise<void> => {
    event.preventDefault();

    setLoading(true);
    setErrorMessage('');

    try {
      await tokensData.SDT.tokenContract.methods
        .approve(gameBridgeContract.options.address, MAX_UINT256)
        .send({
          from: connectedAddress,
          maxPriorityFeePerGas: null,
          maxFeePerGas: null,
        });
    } catch (error) {
      setErrorMessage((error as any).message);
    }
    setLoading(false);
  };

  const onSelectVestingPool = async (
    vestingPoolData: VestingPoolData
  ): Promise<void> => {
    setLoading(true);
    setErrorMessage('');
    setSelectedVestingPoolData(vestingPoolData);

    if (vestingPoolData) {
      await getSelectedVestingPoolData(vestingPoolData.vestingPoolContract);
    } else {
      setSelectedVestingPoolVestingData(undefined);
    }

    setLoading(false);
  };

  const onDepositFromWallet = async (depositValue: string): Promise<void> => {
    setLoading(true);
    setErrorMessage('');

    if (await GameSettingsService.pingServer(selectedGameServer.address)) {
      try {
        await postDepositFromWallet(
          gameBridgeContract,
          depositValue,
          account.hid,
          tokensData.SDT.decimals,
          connectedAddress,
          selectedGameServer.id
        );
      } catch (error) {
        setErrorMessage((error as any).message);
      }
    } else {
      setErrorMessage(MessageText.ServerNotResponding);
    }

    setLoading(false);
  };

  const onDepositFromVesting = async (depositValue: string): Promise<void> => {
    setLoading(true);
    setErrorMessage('');

    if (await GameSettingsService.pingServer(selectedGameServer.address)) {
      try {
        await postDepositFromVestingToGameBridge(
          selectedVestingPoolData.vestingPoolContract,
          depositValue,
          account.hid,
          tokensData.SDT.decimals,
          connectedAddress,
          selectedGameServer.id
        );
      } catch (error) {
        setErrorMessage((error as any).message);
      }
    } else {
      setErrorMessage(MessageText.ServerNotResponding);
    }

    setLoading(false);
  };

  // TODO
  const onRequestWithdraw = async (withdrawValue: string): Promise<void> => {
    setLoading(true);
    setErrorMessage('');

    try {
      await postWithdrawFromGameAccount(
        gameBridgeContract,
        withdrawValue,
        account.hid,
        tokensData.SDT.decimals,
        connectedAddress
      );
    } catch (error) {
      setErrorMessage(error);
    }

    setLoading(false);
  };

  const onChangeGameAccountPassword = async (
    password: string
  ): Promise<void> => {
    setLoading(true);
    setErrorMessage('');

    try {
      await GameSettingsService.changeGameAccountPassword(
        connectedAddress,
        web3,
        account.hid,
        password,
        authToken,
        selectedGameServer.address
      );
    } catch (error) {
      setErrorMessage((error as any).message);
    }

    setLoading(false);
  };

  const onUnassignNft = async (): Promise<void> => {
    dispatchApp(setShowLoader(true));
    setLoading(true);
    setErrorMessage('');

    try {
      await GameSettingsService.unassignNft(
        connectedAddress,
        web3,
        account.hid,
        authToken,
        selectedGameServer.address
      );
    } catch (error) {
      setErrorMessage((error as any).message);
    }

    setLoading(false);
    dispatchApp(setShowLoader(false));
  };

  const onSelectTab = (activeTab: GameAccountTab): void => {
    setActiveTab(activeTab);
    setErrorMessage('');
    setLoading(false);

    setSelectedVestingPoolData(null);
    setSelectedVestingPoolVestingData(null);
  };

  const gameAccountItemClassNames = classNames(
    'staking-pool-item',
    'game-account-item',
    { inactive: account.deleted }
  );
  const inAccountSdt = fromWei(account._sdt, tokensData.SDT.decimals);
  const inAccountRsdt = fromWei(account?.land?._rsdt, tokensData.SDT.decimals);

  const depositTabActive = account?.nft_id;
  const withdrawTabActive = false;

  return (
    <div className={gameAccountItemClassNames}>
      <Accordion.Item
        eventKey={index.toString()}
        className={classNames({ loading: !account })}
      >
        <Accordion.Header
          ref={accordionHeader}
          title={
            assignedNft
              ? `SkyLand #${account?.nft_id} (${assignedNft.attributes.landTier})`
              : // eslint-disable-next-line unicorn/no-nested-ternary
              account && !account?.nft_id
              ? `No SkyLand NFT assigned to this account`
              : undefined
          }
        >
          <GameAccountItemHeader account={account} assignedNft={assignedNft} />
        </Accordion.Header>

        <Accordion.Body>
          <div className="staking-pool-item-body">
            {/* TODO: Fix vesting - left? */}

            <GameAccountItemDetails
              inAccountSdt={inAccountSdt}
              inVesting={selectedVestingPoolVestingData?.amountLeft}
              inWallet={balances.SDT}
              inAccountRsdt={inAccountRsdt}
              pendingDeposit={gameBridgeAccountData?.pendingDeposit}
              pendingWithdrawal={gameBridgeAccountData?.pendingWithdrawal}
            />
            <Tab.Container
              id="tabs-container"
              defaultActiveKey={
                depositTabActive
                  ? 'deposit'
                  : // eslint-disable-next-line unicorn/no-nested-ternary
                  withdrawTabActive
                  ? 'withdraw'
                  : 'settings'
              }
              onSelect={(activeTab: unknown): void =>
                onSelectTab(activeTab as GameAccountTab)
              }
            >
              <Nav variant="pills" className="pills">
                <Nav.Item>
                  <Nav.Link
                    eventKey="deposit"
                    disabled={!depositTabActive || loading}
                  >
                    Deposit SDT
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  {/* <Nav.Link eventKey="withdraw" disabled={loading}> */}
                  {/* TODO */}
                  <Nav.Link
                    eventKey="withdraw"
                    disabled={!withdrawTabActive || loading}
                  >
                    Withdraw
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="settings" disabled={loading}>
                    Account Settings
                  </Nav.Link>
                </Nav.Item>
              </Nav>

              <Tab.Content className="tabs">
                <GameAccountItemDepositTabContent
                  connectedAddress={connectedAddress}
                  loading={loading}
                  depositAllowance={depositAllowance}
                  vestingPoolsData={vestingPoolsData}
                  inWallet={balances.SDT}
                  tokenData={tokensData.SDT}
                  selectedVestingPoolVestingData={
                    selectedVestingPoolVestingData
                  }
                  selectedVestingPoolData={selectedVestingPoolData}
                  pendingDeposit={gameBridgeAccountData?.pendingDeposit}
                  onSelectVestingPool={(
                    vestingPoolData: VestingPoolData
                  ): Promise<void> => onSelectVestingPool(vestingPoolData)}
                  onApprove={(event): Promise<void> => onApprove(event)}
                  onDepositFromWallet={(depositValue): Promise<void> =>
                    onDepositFromWallet(depositValue)
                  }
                  onDepositFromVesting={(depositValue): Promise<void> =>
                    onDepositFromVesting(depositValue)
                  }
                />
                <GameAccountItemWithdrawTabContent
                  loading={loading}
                  inAccountSdt={inAccountSdt}
                  pending={gameBridgeAccountData?.pendingWithdrawal}
                  tokenData={tokensData.SDT}
                  onRequestWithdraw={(withdrawValue): Promise<void> =>
                    onRequestWithdraw(withdrawValue)
                  }
                />
                <GameAccountItemSettingsTabContent
                  loading={loading}
                  errorMessage={errorMessage}
                  account={account}
                  assignedNft={assignedNft}
                  onChangeGameAccountPassword={(password): Promise<void> =>
                    onChangeGameAccountPassword(password)
                  }
                  onUnassignNft={onUnassignNft}
                  onHideModal={(): void => setErrorMessage('')}
                />
                {errorMessage && (
                  <Message
                    descriptionText={
                      errorMessage?.includes('404')
                        ? `${errorMessage} ${MessageText.Fees}`
                        : errorMessage
                    }
                    messageType={MessageType.Error}
                  />
                )}
              </Tab.Content>
            </Tab.Container>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </div>
  );
};

export default GameAccountItem;
