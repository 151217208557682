import classNames from 'classnames';
import {
  CreateGameAccountModal,
  GameAccountItem,
  SectionHeader,
} from 'components';
import { Message } from 'components/Message';
import { MessageType } from 'helpers';
import { GameAccount, GameServer, Nft } from 'models';
import React, { useState } from 'react';
import { Accordion } from 'react-bootstrap';

export interface GameAccountsProps {
  gameAccounts: GameAccount[];
  gameServers: GameServer[];
  nfts: Nft[];
  error: string;
}

export const GameAccounts: React.FC<GameAccountsProps> = ({
  gameAccounts,
  gameServers,
  nfts,
  error,
}) => {
  const [showModal, setShowModal] = useState(null);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  const partition = (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
    arr: any[],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
    fn: (arg0: any, arg1: any, arg2: any) => any
  ) =>
    arr.reduce(
      (acc, val, i, arr) => {
        acc[fn(val, i, arr) ? 0 : 1].push(val);
        return acc;
      },
      [[], []]
    );
  const gameAccountsSorted = partition(
    gameAccounts,
    (acc) => acc.nft_id > 0
  ).flat();

  const gameAccountItems = gameAccountsSorted.map(
    (account: GameAccount, index: number) => {
      const assignedNft = nfts?.find((nft) => nft.edition === account.nft_id);
      return (
        <GameAccountItem
          key={`account-item-${index}`}
          assignedNft={assignedNft}
          index={index}
          account={account}
        ></GameAccountItem>
      );
    }
  );
  const canCreateAccount = nfts?.length > 0;

  return (
    <div className="section-container">
      <div className="game-accounts">
        <SectionHeader
          title="Game accounts"
          description="Manage existing game accounts or create new."
        />

        <Accordion defaultActiveKey="0">
          {gameAccountItems ? <div>{gameAccountItems}</div> : ''}
        </Accordion>

        <button
          className={classNames(
            'btn nowrap mt-32 w-100',
            gameAccounts?.length ? 'btn-secondary' : 'btn-primary'
          )}
          disabled={!canCreateAccount}
          onClick={(): void => setShowModal(true)}
        >
          <i className="fa fa-plus mr-8" />
          Create new account
        </button>

        {canCreateAccount ? (
          ''
        ) : (
          <Message messageType={MessageType.Primary}>
            You cannot create a new account due to lack of unassigned SkyLands
            NFTs on your wallet. If you have NFTs previously purchased on BNB
            Smart Chain network you can bridge them{' '}
            <a
              href="https://app.mintlabz.io/bridge"
              target="_blank"
              className="link primary"
              rel="noreferrer"
            >
              here
            </a>
            . You can also purchase new NFTs{' '}
            <a
              href="https://opensea.io/collection/skylands-3"
              target="_blank"
              className="link primary"
              rel="noreferrer"
            >
              here
            </a>
            .
          </Message>
        )}
      </div>

      {showModal ? (
        <CreateGameAccountModal
          gameAccounts={gameAccounts}
          gameServers={gameServers}
          nfts={nfts}
          error={error}
          hideModal={(): void => setShowModal(false)}
        ></CreateGameAccountModal>
      ) : (
        ''
      )}
    </div>
  );
};
