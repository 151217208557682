/* eslint-disable complexity */
import BigNumber from 'bignumber.js';
import { ButtonWithSpinner, Message, PercentageButtons } from 'components';
import { isCorrectValue, MessageText, MessageType, numbersOnly } from 'helpers';
import { TokenData } from 'models';
import React, { useEffect, useState } from 'react';
import { Tab } from 'react-bootstrap';

interface GameAccountItemWithdrawTabContentProps {
  loading: boolean;
  inAccountSdt: string;
  pending: string;
  tokenData: TokenData;
  onRequestWithdraw: (withdrawValue: string) => void;
}

export const GameAccountItemWithdrawTabContent: React.FC<
  GameAccountItemWithdrawTabContentProps
> = ({ loading, inAccountSdt, pending, tokenData, onRequestWithdraw }) => {
  useEffect(() => {
    if (!loading) {
      setWithdrawValue('0');
    }
  }, [loading]);

  const [withdrawValue, setWithdrawValue] = useState<string>('0');
  const withdrawValueBN = new BigNumber(withdrawValue);
  const inAccountBn = new BigNumber(inAccountSdt);

  const inputDisabled = inAccountBn.isEqualTo(0);
  const percentageButtonsDisabled = loading || inAccountBn.isEqualTo(0);

  const withdrawButtonDisabled =
    +inAccountSdt == 0 ||
    +withdrawValue == 0 ||
    withdrawValueBN.isGreaterThan(inAccountBn);

  const onApplyPercentage = (percentage: number): void => {
    const roundedNumber = inAccountBn
      .times(percentage)
      .div(100)
      .toFixed(tokenData.decimals);

    setWithdrawValue(roundedNumber);
  };

  const onWithdrawValueChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const value = event.target.value;

    if (isCorrectValue(value, tokenData.decimals)) {
      setWithdrawValue(value);
    }
  };

  return (
    <Tab.Pane eventKey="withdraw" unmountOnExit={true} mountOnEnter={true}>
      <div className="tab-content">
        <div className="unstake-section">
          <div className="unstake-section-description">
            You can request SDT withdrawal from game here. Your request should
            be processed within 24 hours. After it is accepted, you will be able
            to claim it.
          </div>

          {+pending === 0 ? (
            <>
              {+inAccountSdt > 0 ? (
                <>
                  <div className="unstake-section-actions">
                    <input
                      className="s-input"
                      placeholder="0.00"
                      onChange={onWithdrawValueChange}
                      value={withdrawValue}
                      onKeyPress={numbersOnly}
                      disabled={inputDisabled}
                    />

                    <PercentageButtons
                      disabled={percentageButtonsDisabled}
                      applyPercentage={(percentage: number): void =>
                        onApplyPercentage(percentage)
                      }
                    />

                    <ButtonWithSpinner
                      text="Request Withdrawal"
                      classes="btn btn-primary section-action-button"
                      onClick={(): void => onRequestWithdraw(withdrawValue)}
                      disabled={withdrawButtonDisabled}
                      loading={loading}
                    />
                  </div>
                  {withdrawValueBN.isGreaterThan(inAccountBn) && (
                    <Message
                      messageType={MessageType.Error}
                      descriptionText={
                        MessageText.WithdrawValueGreaterThanAvailable
                      }
                    />
                  )}
                </>
              ) : (
                <Message
                  messageType={MessageType.Primary}
                  descriptionText="You don't have any SDT in your account"
                />
              )}
            </>
          ) : (
            <Message
              messageType={MessageType.Primary}
              descriptionText="Your withdrawal is Pending"
            />
          )}
        </div>
      </div>
    </Tab.Pane>
  );
};
