import { AppChain, skynityConfig } from 'helpers';

import { AppChainGroup, evmChains } from './config.helper';
import Web3 from 'web3';

export enum NoConnectionType {
  WrongNetwork = 'wrong-network',
  WalletNotConnected = 'wallet-not-connected',
}

export const isPermittedNetwork = (chainId: number): boolean =>
  !!skynityConfig.permittedNetworks.some(
    (network) => network.chainId == chainId
  );

export const isChain = (appChain: AppChain | AppChainGroup): boolean => {
  const isEvmChain = evmChains.includes(
    process.env.REACT_APP_CHAIN as AppChain
  );

  return appChain === AppChainGroup.Evm
    ? isEvmChain
    : process.env.REACT_APP_CHAIN === appChain;
};

export const getChainName = (appChain: AppChain): string => {
  switch (appChain) {
    case AppChain.Bsc: {
      return 'BNB Smart Chain';
    }

    case AppChain.Base: {
      return 'Base Network';
    }
    default: {
      throw new Error('Unsupported Chain');
    }
  }
};

export const switchToPrimaryChain = async (web3: Web3): Promise<void> => {
  try {
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [
        {
          chainId: web3.utils.toHex(skynityConfig.primaryNetwork.chainId),
        },
      ],
    });
  } catch (error) {
    // This error code indicates that the chain has not been added to MetaMask
    if ((error as { code?: number }).code === 4902) {
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            ...skynityConfig.primaryNetwork.addChainParams,
            chainId: web3.utils.toHex(skynityConfig.primaryNetwork.chainId),
          },
        ],
      });
    }
  }
};

export const addTokenToWallet = async (networkId: number): Promise<void> => {
  await window.ethereum.request({
    method: 'wallet_watchAsset',
    params: {
      type: 'ERC20',
      options: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        address: (skynityConfig.tokens.SDT.tokenContractJSON.networks as any)[
          networkId
        ]?.address,
        // symbol: skynityConfig.tokens.SDT.symbol,
        // decimals: skynityConfig.tokens.SDT.decimals,
        // image: 'https://foo.io/token-image.svg',
      },
    },
  });
};
