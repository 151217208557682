import skynityLogo from 'assets/svg/skynity-logo.svg';
import { applyMask, RoutingPaths } from 'helpers';
import React from 'react';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectConnectedAddress, selectConnectedNetwork } from 'store';

interface HeaderProps {
  connectWallet?: () => void;
  disconnectWallet?: () => void;
}

export const Header: React.FC<HeaderProps> = ({
  connectWallet,
  disconnectWallet,
}) => {
  const connectedAddress = useSelector(selectConnectedAddress);
  const connectedNetwork = useSelector(selectConnectedNetwork);

  return (
    <header className="header">
      <Navbar collapseOnSelect expand="md" variant="dark">
        <div className="container-fluid">
          <Navbar.Brand as={Link} to={RoutingPaths.Home}>
            <img src={skynityLogo} alt="Skynity logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="justify-content-center flex-grow-1">
              <Nav.Link as={Link} to={RoutingPaths.Home}>
                Home
              </Nav.Link>
              <NavDropdown title="SDT Token" id="basic-nav-dropdown">
                {/* <NavDropdown.Item
                  href={skynityConfig.additionalData.tokenLink}
                  className="link secondary font-bold"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Buy
                </NavDropdown.Item> */}
                {/* <NavDropdown.Divider /> */}
                <NavDropdown.Item
                  className="link secondary"
                  as={Link}
                  to={RoutingPaths.Staking}
                  disabled={true}
                >
                  Staking
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="link secondary"
                  as={Link}
                  to={RoutingPaths.Vesting}
                  disabled={true}
                >
                  Vesting
                </NavDropdown.Item>
                {/* <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">
                  Separated link
                </NavDropdown.Item> */}
              </NavDropdown>
              <NavDropdown title="SkyLands" id="basic-nav-dropdown">
                <NavDropdown.Item
                  href="https://opensea.io/collection/skylands-3"
                  target="_blank"
                >
                  Marketplace&nbsp;
                  <i className="fa fa-arrow-up-right-from-square"></i>
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://app.mintlabz.io/bridge"
                  target="_blank"
                >
                  Bridge&nbsp;
                  <i className="fa fa-arrow-up-right-from-square"></i>
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link as={Link} to={RoutingPaths.Settings}>
                Game panel
              </Nav.Link>

              {/* TODO: enable when ready*/}
              {/* <NavDropdown title="Pregame Sale" id="basic-nav-dropdown">
                <NavDropdown.Item
                  className="link secondary font-bold"
                  as={Link}
                  to={RoutingPaths.PrivateSale}
                  disabled={true}
                >
                  Private
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="link secondary font-bold"
                  as={Link}
                  to={RoutingPaths.PublicSale}
                  disabled={true}
                >
                  Public
                </NavDropdown.Item>
              </NavDropdown> */}
            </Nav>
            <Nav className="wallet-section">
              {connectedAddress && (
                <>
                  <NavDropdown
                    className="font-small font-bold secondary nowrap"
                    title={applyMask(connectedAddress)}
                  >
                    <i
                      className="fa fa-power-off pointer"
                      onClick={disconnectWallet}
                      title="Disconnect wallet"
                    ></i>
                    <NavDropdown.Header>
                      <span
                        className={`badge bg-${
                          connectedNetwork ? 'success' : 'danger'
                        } font-extra-small`}
                      >
                        {connectedNetwork?.name || 'Wrong network'}
                      </span>
                    </NavDropdown.Header>

                    <NavDropdown.Item
                      href="https://opensea.io/account/private"
                      target="_blank"
                    >
                      My SkyLands NFTs&nbsp;
                      <i className="fa fa-arrow-up-right-from-square"></i>
                    </NavDropdown.Item>
                  </NavDropdown>
                </>
              )}
              {!connectedAddress && (
                <button
                  className="btn btn-secondary nowrap"
                  onClick={connectWallet}
                >
                  Connect wallet
                </button>
              )}
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </header>
  );
};
