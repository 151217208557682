import classNames from 'classnames';
import { ButtonWithSpinner } from 'components/ButtonWithSpinner';
import { Message } from 'components/Message';
import { SectionHeader } from 'components/SectionHeader/SectionHeader';
import { MessageType } from 'helpers';
import { GameServer, GameServerStatus } from 'models';
import React, { useEffect } from 'react';
import { ListGroup, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  getGameServers,
  selectGameServers,
  selectSelectedGameServer,
} from 'store';

export interface GameSettingsLoginProps {
  loading: boolean;
  error: string;
  onLogin: () => void;
  onSelectGameServer: (gameServer: GameServer) => void;
}

export const GameSettingsLogin: React.FC<GameSettingsLoginProps> = ({
  loading,
  error,
  onLogin,
  onSelectGameServer,
}) => {
  const dispatch = useDispatch();
  const gameServers: GameServer[] = useSelector(selectGameServers);
  const selectedGameServer = useSelector(selectSelectedGameServer);
  const gameServersListSort = (a: GameServer, b: GameServer): number => {
    const prevAccounts = a.stats.wallet_stats?.accounts;
    const nextAccounts = b.stats.wallet_stats?.accounts;

    if (prevAccounts > 0 && nextAccounts > 0) {
      return nextAccounts - prevAccounts; // sort by accounts descending
    } else if (prevAccounts > 0) {
      return -1; // keep a above b
    } else if (nextAccounts > 0) {
      return 1; // keep b above a
    } else {
      // both have no accounts, sort by players descending
      return b.stats.players - a.stats.players;
    }
  };

  const gameServersList = gameServers && (
    <>
      <div className="mb-8 bold text-background-gold">Available servers</div>

      <ListGroup className="mb-16">
        {gameServers
          ?.slice()
          ?.sort((a, b) => gameServersListSort(a, b))
          ?.map((gameServer: GameServer, index) => {
            const disabled =
              gameServer.status === GameServerStatus.Offline ||
              !!gameServer.stats.error;

            return (
              <ListGroup.Item
                action
                key={index}
                onClick={(): void => onSelectGameServer(gameServer)}
                disabled={disabled}
                active={gameServer.id === selectedGameServer?.id}
              >
                <div
                  className={classNames(
                    'flex-container',
                    'align-center',
                    'justify-between'
                  )}
                >
                  <div>
                    {!disabled && (
                      <i
                        className={classNames({
                          fas: true,
                          'fa-circle': true,
                          'text-success':
                            gameServer.status === GameServerStatus.Online,
                          'mr-8': true,
                        })}
                        title={GameServerStatus.Online}
                      ></i>
                    )}
                    {gameServer.name} -{' '}
                    {gameServer.stats.wallet_stats?.accounts ?? 0} accounts
                  </div>
                  <div>
                    {gameServer.stats.players ?? 0} / {gameServer.players_limit}
                  </div>
                </div>
              </ListGroup.Item>
            );
          })}
      </ListGroup>
    </>
  );

  useEffect(() => {
    dispatch(getGameServers());
  }, []);

  return (
    <div className="section-container" key={`${loading}`}>
      <SectionHeader
        title="Game panel login"
        description="Select a desired server from the below list of available servers and login with your wallet."
      />

      <>
        {gameServers ? (
          <>
            {gameServersList}

            <ButtonWithSpinner
              text="Login"
              classes="btn btn-primary w-100"
              onClick={(): void => onLogin()}
              loading={loading}
              disabled={loading || !selectedGameServer}
            />
          </>
        ) : (
          <div className="mb-16 flex-container align-center justify-center">
            <Spinner
              animation="border"
              role="status"
              variant="light"
              size="sm"
            />
          </div>
        )}
      </>

      {error ? (
        <Message
          messageType={MessageType.Error}
          descriptionText={error}
        ></Message>
      ) : (
        ''
      )}
    </div>
  );
};
