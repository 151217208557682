// import { RoutingPaths } from 'helpers';
import React, { useEffect, useState } from 'react';
import { addTokenToWallet, skynityConfig } from '../../helpers';
import { useSelector } from 'react-redux';
import { selectConnectedNetwork } from '../../store';
// import { Link } from 'react-router-dom';
import metamaskFox from 'assets/svg/metamask_fox.svg';
import { Link } from 'react-router-dom';

export const Hero: React.FC = () => {
  const connectedNetwork = useSelector(selectConnectedNetwork);

  const [tokenAddress, setTokenAddress] = useState<string>();
  const [nftAddress, setNftAddress] = useState<string>();

  useEffect(() => {
    if (connectedNetwork) {
      setTokenAddress(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (skynityConfig.tokens.SDT.tokenContractJSON.networks as any)[
          connectedNetwork.chainId
        ]?.address
      );
      setNftAddress(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (skynityConfig.nftContractJSON.networks as any)[
          connectedNetwork.chainId
        ]?.address
      );
    }
  }, [connectedNetwork]);

  return (
    <div className="hero">
      <div className="container text-center">
        <div className="hero-wrapper">
          <h1 className="hero-header mb-24">
            Welcome to <div className="font-bold">Skynity App</div>
          </h1>

          <h3 className="hero-description">Get ready to fight in the sky</h3>

          {/* TODO: Update description */}
          {/* <h3 className="hero-description-2">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc varius
          ut ex sit amet tempus. Integer porttitor pellentesque leo eget
          pretium. Aliquam ut dui ligula. Integer volutpat dapibus mi. Aliquam
          eu nulla ut ipsum blandit hendrerit rhoncus vitae nisl. Duis libero
          sem, congue et dapibus nec, ornare at lorem.
          </h3> */}

          <div className="hero-buttons-container">
            <a
              href="https://skynity.gitbook.io/wiki"
              target="_blank"
              rel="noreferrer"
              className="btn btn-primary"
            >
              Game Wiki
            </a>

            {/* TODO: Update or remove */}
            {/* <Link to={RoutingPaths.PrivateSale} className="btn btn-primary">
              Private Sale
            </Link>
            <Link
              to={RoutingPaths.Staking}
              className="btn btn-primary inactive"
            >
              Stake
            </Link> */}
          </div>

          {connectedNetwork && (
            <h3 className="hero-description-2 hero-tokens-info">
              <div>SkyDust SDT (prelaunch) token contract address:</div>
              <div>
                <span className="bold">{tokenAddress}</span>
                &nbsp;&nbsp;
                <span className="nowrap">
                  <a
                    className="link link-secondary fa-regular fa-copy pointer"
                    onClick={(): void => {
                      navigator.clipboard.writeText(tokenAddress);
                    }}
                    title="Copy to clipboard"
                  ></a>
                  &nbsp;
                  <a
                    className="metamask-button link link-secondary pointer"
                    title="Add to metamask"
                    onClick={(): void => {
                      addTokenToWallet(connectedNetwork.chainId);
                    }}
                  >
                    <img src={metamaskFox} className=""></img>
                  </a>
                </span>
              </div>
              <div>SkyLands NFT colelction address:</div>
              <div>
                <span className="bold">{nftAddress}</span>
                &nbsp;&nbsp;
                <span className="nowrap">
                  <a
                    className="link link-secondary fa-regular fa-copy pointer"
                    onClick={(): void => {
                      navigator.clipboard.writeText(nftAddress);
                    }}
                    title="Copy to clipboard"
                  ></a>
                  &nbsp;
                  <Link
                    className="link link-secondary fa-solid fa-cart-shopping"
                    to="https://opensea.io/collection/skylands-3"
                    target="_blank"
                    title="Trade on NFT marketplace"
                  ></Link>
                  &nbsp;
                  <Link
                    className="link link-secondary fa-solid fa-right-left"
                    to="https://app.mintlabz.io/bridge"
                    target="_blank"
                    title="Bridge between networks"
                  ></Link>
                </span>
                &nbsp;
              </div>
            </h3>
          )}
        </div>
      </div>
    </div>
  );
};
