import React, { PropsWithChildren, ReactNode, useState } from 'react';
import { Collapse } from 'react-bootstrap';

export const ButtonWithMessage: React.FC<
  PropsWithChildren<{
    buttonText: string;
    buttonClass?: string;
    messageButtonClass?: string;
    messageHeader?: ReactNode;
    messageClass?: string;
    className?: string;
    onClick?: () => void;
  }>
> = ({
  buttonText,
  buttonClass,
  messageButtonClass,
  messageHeader,
  messageClass,
  className,
  onClick,
  children,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={className}>
      <div className="btn-group w-100">
        <button
          className={`btn btn-${buttonClass || 'primary'} w-100`}
          onClick={onClick}
        >
          {buttonText}
        </button>
        <button
          className={`btn btn-${messageButtonClass || 'secondary'}`}
          onClick={(): void => setOpen(!open)}
        >
          ?
        </button>
      </div>
      <Collapse in={open}>
        <div
          className={`message ${messageClass || 'primary'} position-relative`}
        >
          {messageHeader && (
            <div className="message-header">{messageHeader}</div>
          )}
          <div className={`message-description`}>{children}</div>
          <i
            className="fa fa-xmark position-absolute top-0 end-0 mt-2 me-2 pointer"
            onClick={(): void => setOpen(false)}
          ></i>
        </div>
      </Collapse>
    </div>
  );
};
