import { getVestingPoolData } from 'helpers';
import {
  DepositSource,
  MainTokenSymbol,
  TokenData,
  VestingPoolData,
} from 'models';
import React, { useEffect, useState } from 'react';
import { ListGroup } from 'react-bootstrap';

interface GameAccountItemDepositTabContentSourceSelectorProps {
  connectedAddress: string;
  tokenData: TokenData;
  depositSource: DepositSource;
  loading: boolean;
  vestingPoolsData: VestingPoolData[];
  selectedVestingPoolData: VestingPoolData;
  onSelectVestingPool: (vestingPoolData: VestingPoolData) => void;
  onSetDepositSource: (depositSource: DepositSource) => void;
}

export const GameAccountItemDepositTabContentSourceSelector: React.FC<
  GameAccountItemDepositTabContentSourceSelectorProps
> = ({
  connectedAddress,
  tokenData,
  depositSource,
  loading,
  vestingPoolsData,
  selectedVestingPoolData,
  onSelectVestingPool,
  onSetDepositSource,
}) => {
  const [depositListGroup, setDepositListGroup] = useState(null);
  const getVestingPoolAmount = async (
    vestingPoolData: VestingPoolData
  ): Promise<string> => {
    try {
      const payload = await getVestingPoolData(
        connectedAddress,
        tokenData,
        vestingPoolData.vestingPoolContract
      );

      return payload.amountLeft;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  useEffect(() => {
    if (depositSource === 'vesting') {
      const createDepositListGroup = async (): Promise<any> => {
        const items = await Promise.all(
          vestingPoolsData
            ?.slice()
            // Uncomment and complete the sort function if sorting is needed
            // ?.sort((a, b) => a.propertyToSortBy - b.propertyToSortBy)
            ?.map(async (vestingPoolData, index) => {
              const amount = await getVestingPoolAmount(vestingPoolData);

              return (
                <ListGroup.Item
                  action
                  key={`vesting-pool-list-group-item-${index}`}
                  onClick={(): void => onSelectVestingPool(vestingPoolData)}
                  active={
                    vestingPoolData.poolType ===
                    selectedVestingPoolData?.poolType
                  }
                >
                  <div className="flex-container justify-between align">
                    <div>{vestingPoolData.poolType}</div>
                    <div>
                      {amount} {MainTokenSymbol.SDT}
                    </div>
                  </div>
                </ListGroup.Item>
              );
            }) || []
        );

        setDepositListGroup(items);
      };

      createDepositListGroup();
    }
  }, [depositSource, loading]);

  return (
    <>
      <div className="mb-8 primary bold text-background-gold ">
        Select Deposit source
      </div>

      {
        <ListGroup className="mb-16">
          <ListGroup.Item
            action
            onClick={(): void => onSetDepositSource('wallet')}
            disabled={loading}
            active={depositSource === 'wallet'}
          >
            Deposit from wallet
          </ListGroup.Item>

          <ListGroup.Item
            action
            onClick={(): void => onSetDepositSource('vesting')}
            disabled={loading || !vestingPoolsData?.length}
            active={depositSource === 'vesting'}
          >
            {`Deposit from vesting ${
              vestingPoolsData?.length
                ? ''
                : '(You do not have any vesting pools available)'
            }`}
          </ListGroup.Item>
        </ListGroup>
      }

      {depositSource === 'vesting' ? (
        <>
          <div className="mb-8 primary bold text-background-gold ">
            Select Vesting Pool
          </div>

          <ListGroup className="mb-16">{depositListGroup}</ListGroup>
        </>
      ) : (
        ''
      )}
    </>
  );
};
