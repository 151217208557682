import { fromWei, toWei } from 'helpers';
import { GameBridgeAccountData, TokenData } from 'models';
import { Contract } from 'web3-eth-contract';

export const getGameBridgeData = async (
  connectedAddress: string,
  tokenData: TokenData,
  gameBridgeContract: Contract,
  serverId: number
): Promise<GameBridgeAccountData> => {
  const accountData: GameBridgeAccountData = await gameBridgeContract.methods
    .getAccountData(serverId, connectedAddress)
    .call();

  // Pending deposit
  const pendingDepositWei = (accountData.pendingDeposit as any)?.amount;
  const pendingDeposit = fromWei(pendingDepositWei, tokenData.decimals);

  // Pending withdrawal
  const pendingWithdrawalWei = accountData.pendingWithdrawal;
  const pendingWithdrawal = fromWei(pendingWithdrawalWei, tokenData.decimals);

  return {
    pendingDeposit,
    pendingWithdrawal,
    isBlacklisted: accountData.isBlacklisted,
  };
};

export const postDepositFromWallet = async (
  gameBridgeContract: Contract,
  depositValue: string,
  accountId: string,
  decimals: number,
  connectedAddress: string,
  serverId: number
): Promise<unknown> => {
  const depositValueWei = toWei(depositValue, decimals);

  return await gameBridgeContract.methods
    .deposit(serverId, depositValueWei, accountId)
    .send({
      from: connectedAddress,
      maxPriorityFeePerGas: null,
      maxFeePerGas: null,
    });
};

export const postWithdrawFromGameAccount = async (
  gameBridgeContract: Contract,
  withdrawValue: string,
  accountId: string,
  decimals: number,
  connectedAddress: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  const withdrawValueWei = toWei(withdrawValue, decimals);

  return await gameBridgeContract.methods
    .withdraw(withdrawValueWei, accountId)
    .send({
      from: connectedAddress,
      maxPriorityFeePerGas: null,
      maxFeePerGas: null,
    });
};
