import { ApiNft, Nft, NftAttributes, NftRarity, NftTraitType } from 'models';
import { from, map, mergeMap, Observable, range, toArray } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { Contract } from 'web3-eth-contract';

export class NftService {
  public static getNftBalance(
    connectedAddress: string,
    contract: Contract
  ): Observable<string> {
    const nftBalance: Promise<string> = contract.methods
      .balanceOf(connectedAddress)
      .call();

    return from(nftBalance);
  }

  public static getNftsIds = (
    connectedAddress: string,
    nftBalance: number,
    contract: Contract
  ): Observable<number[]> =>
    range(0, nftBalance).pipe(
      mergeMap((i: number) => {
        const nftId: Promise<number> = contract.methods
          .tokenOfOwnerByIndex(connectedAddress, i)
          .call();

        return from(nftId);
      }),
      toArray()
    );

  public static getAllNfts = (nftsIds: number[]): Observable<Nft[]> =>
    from(nftsIds).pipe(
      mergeMap((nftsId) =>
        ajax<ApiNft>({
          url: `${process.env.REACT_APP_IPFS_BASE_SKYNITY_NFT_URL}/${nftsId}.json`,
          method: 'GET',
        }).pipe(
          map((response) => {
            const nft: ApiNft = response.response;
            const nftImage = `${
              process.env.REACT_APP_IPFS_BASE_SKYNITY_NFT_IMAGE_URL
            }/${nft.image.split('://')[1]}`;
            const nftAttributes = nft.attributes.reduce((acc, attribute) => {
              const key = attribute.trait_type.replace(/\s/g, '');
              const decapitalizedKey = (key.charAt(0).toLowerCase() +
                key.slice(1)) as Uncapitalize<keyof typeof NftTraitType>;
              acc[decapitalizedKey] = attribute.value;

              return acc;
            }, {} as NftAttributes);

            return {
              ...nft,
              image: nftImage,
              attributes: nftAttributes,
              rarityScore:
                NftRarity[nftAttributes.landTier as keyof typeof NftRarity],
            };
          })
        )
      ),
      toArray(),
      map((nfts: Nft[]) =>
        nfts.sort((a, b) => {
          const tierComparison = a.attributes.landTier.localeCompare(
            b.attributes.landTier
          );

          if (tierComparison === 0) {
            return b.edition - a.edition;
          } else {
            return tierComparison;
          }
        })
      )
    );
}
