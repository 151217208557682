import skynityLogo from 'assets/svg/skynity-logo.svg';
import React from 'react';

export const Preloader: React.FC<{ transparent?: boolean }> = ({
  transparent,
}) => (
  <div className={`preloader${transparent ? ' transparent' : ''}`}>
    <div className="preloader-container">
      <div className="preloader-image">
        <img src={skynityLogo} alt="Skynity loader" />
      </div>
    </div>
  </div>
);

export default Preloader;
