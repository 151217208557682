/* eslint-disable sonarjs/no-duplicate-string */
import { SkynityCookie } from 'helpers';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import {
  CreateGameAccountPayload,
  CreateGameAccountResponse,
  GameAccount,
  GameServer,
  GameServerStats,
  LoginResponse,
} from 'models';
import { defer, from, map, mergeMap, Observable } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import Web3 from 'web3';

export class GameSettingsService {
  public static login = (
    address: string,
    web3: Web3,
    selectedGameServer: GameServer
  ): Observable<LoginResponse> => {
    const getToken = async (): Promise<LoginResponse> => {
      try {
        const data = {
          timestamp: Date.now(),
        };
        const signature = await web3.eth.personal.sign(
          JSON.stringify(data),
          address,
          null
        );
        const response = await fetch(
          `${selectedGameServer.address}/web3api/auth/login`,
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              data,
              address,
              signature,
            }),
          }
        );
        const responseJson = await response.json();

        if (response.ok) {
          const authToken = (responseJson as LoginResponse).auth_token;
          const decodedToken = jwtDecode(authToken);
          const expirationTime = new Date(decodedToken.exp * 1000);

          Cookies.set(SkynityCookie.AuthToken, authToken, {
            expires: expirationTime,
          });
          Cookies.set(
            SkynityCookie.SelectedGameServer,
            JSON.stringify(selectedGameServer)
          );

          return responseJson as LoginResponse;
        } else {
          throw new Error(responseJson.message);
        }
      } catch (error: any) {
        throw new Error(error.message);
      }
    };

    return from(getToken());
  };

  public static getGameServers(address: string): Observable<GameServer[]> {
    /*
    Refactored for faster speeds and to allow connectng to localhost server.
    - query /game-servers main API /game-servers without query_server param
    - query each server individually for stats param
    Main API /game-servers without query_server param results in main API not querying servers for stats which was slow (PHP API not allowing concurrent requests).
    Additionally this didn't allow for localhost server addresses (invisible for the the API server).
    */
    const getServerStat = async (
      serverAddress: string
    ): Promise<GameServerStats> => {
      try {
        const result = await fetch(
          `${serverAddress}/server/info?${new URLSearchParams({
            wallet: address,
            t: Date.now().toString(),
          }).toString()}`,
          {
            signal: serverAddress.includes('localhost')
              ? AbortSignal.timeout(100)
              : undefined,
          }
        );
        return result.ok ? await result.json() : null;
      } catch {
        return { error: true };
      }
    };
    const _getGameServers = async (): Promise<GameServer[]> => {
      const result = await (
        await fetch(
          `${process.env.REACT_APP_CROSSSERVER_API_URL}/game-servers/${
            process.env.REACT_APP_CROSSSERVER_API_POSTFIX
          }?${new URLSearchParams({
            wallet: address,
            t: Date.now().toString(),
          }).toString()}`
        )
      )
        // eslint-disable-next-line unicorn/no-await-expression-member
        .json();
      return await Promise.all(
        result.data.servers.map(async (server: GameServer) => ({
          ...server,
          stats: await getServerStat(server.address),
        }))
      );
    };
    return defer(() => from(_getGameServers()));
  }

  public static async pingServer(address: string): Promise<boolean> {
    try {
      const result = await fetch(`${address}/server/info`);
      return result.ok;
    } catch {
      return false;
    }
  }

  public static getGameAccounts(
    authToken: string,
    apiUrl: string
  ): Observable<GameAccount[]> {
    return ajax<{ gameAccounts: GameAccount[] }>({
      url: `${apiUrl}/web3api/accounts`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    }).pipe(map((response) => response.response.gameAccounts));
  }

  public static unassignNft = async (
    address: string,
    web3: Web3,
    hid: string,
    authToken: string,
    apiUrl: string
  ): Promise<any> => {
    const data = {
      account: {
        hid,
      },
    };

    const signature = await web3.eth.personal.sign(
      JSON.stringify(data),
      address,
      null
    );
    const response = await fetch(`${apiUrl}/web3api/account/nft/unassign`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        data,
        address,
        signature,
      }),
    });
    const responseJson = await response.json();

    if (response.ok) {
      return responseJson;
    } else {
      throw new Error(responseJson.message);
    }
  };

  public static changeGameAccountPassword = async (
    address: string,
    web3: Web3,
    hid: string,
    password: string,
    authToken: string,
    apiUrl: string
  ): Promise<any> => {
    const data = {
      account: {
        hid,
        password,
      },
    };

    const signature = await web3.eth.personal.sign(
      JSON.stringify(data),
      address,
      null
    );
    const response = await fetch(`${apiUrl}/web3api/account/password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        data,
        address,
        signature,
      }),
    });
    const responseJson = await response.json();

    if (response.ok) {
      return responseJson;
    } else {
      throw new Error(responseJson.message);
    }
  };

  public static createGameAccount = (
    address: string,
    web3: Web3,
    authToken: string,
    createGameAccountPayload: CreateGameAccountPayload,
    apiUrl: string
  ): Observable<CreateGameAccountResponse> => {
    const data = {
      account: {
        name: createGameAccountPayload.accountName,
        password: createGameAccountPayload.accountPassword,
        nft_id: createGameAccountPayload.nftId,
      },
    };
    const signature: Promise<string> = web3.eth.personal.sign(
      JSON.stringify(data),
      address,
      null
    );

    return from(signature).pipe(
      mergeMap((signature: string) => {
        const body = {
          data,
          address: address,
          signature,
        };

        return ajax<CreateGameAccountResponse>({
          url: `${apiUrl}/web3api/account/create`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify(body),
        }).pipe(map((response) => response.response));
      })
    );
  };
}
