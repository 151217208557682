import { DataItem } from 'components';
import { getTotalAcquiredSDT, roundNumber } from 'helpers';
import { MainTokenSymbol, TokenBalances, TokenPrices } from 'models';
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import Web3 from 'web3';

interface InfoBarProps {
  balances: TokenBalances;
  tokensPrices: TokenPrices;
  connectedAddress: string;
  web3: Web3;
}

export const InfoBar: React.FC<InfoBarProps> = ({
  balances,
  tokensPrices,
  connectedAddress,
  web3,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [preboughtTokens, setPreboughtTokens] = useState(null);

  useEffect(() => {
    if (connectedAddress) {
      getPreboughtTokens();
    }
  }, [connectedAddress]);

  const getPreboughtTokens = async (): Promise<void> => {
    try {
      const tokens = await getTotalAcquiredSDT(web3, connectedAddress);

      setPreboughtTokens(tokens);
    } catch {
      setPreboughtTokens({ total: 'Error' });
    }
  };

  return (
    <section className="info-bar">
      <div className="container-inner">
        <div className="info-bar-container container">
          <DataItem
            label="SDT in wallet (prelaunch tokens)"
            iconClasses="fas fa-wallet"
          >
            {balances ? (
              `${roundNumber(balances.SDT)} ${MainTokenSymbol.SDT}`
            ) : (
              <Spinner
                animation="border"
                role="status"
                variant="light"
                size="sm"
              />
            )}
          </DataItem>

          {/* TODO: disabled temporarily */}
          {/* <DataItem label="SDT Price" iconClasses="fas fa-dollar-sign">
            {tokensPrices ? (
              <a
                href={skynityConfig.additionalData.tokenChartLink}
                className="link pointer secondary font-bold"
              >
                {parseCurrency(
                  tokensPrices.SDT,
                  Number.parseFloat(tokensPrices.SDT) < 10 ? 4 : undefined
                )}
              </a>
            ) : (
              <Spinner
                animation="border"
                role="status"
                variant="light"
                size="sm"
              />
            )}
          </DataItem> */}

          {/* TODO: disabled temporarily */}
          {/* <DataItem label="Tokens from pregame sales" iconClasses="fas fa-lock">
            {preboughtTokens ? (
              preboughtTokens.total
            ) : (
              <Spinner
                animation="border"
                role="status"
                variant="light"
                size="sm"
              />
            )}
          </DataItem> */}
        </div>
      </div>
    </section>
  );
};
